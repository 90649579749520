/* eslint-disable no-prototype-builtins */
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { notification, Button } from "antd";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { httpsCallable, getFunctions } from "firebase/functions";

import type { FirebaseError } from "firebase/app";

const vapidKey =
  "BLk-EqR_jTwWSMpEcqUYYqFI3Xio6cOo1ErtGdvrvFRf5x8m0etnj4MW9kb-MSPISHXMBJHSYlXv26olWV1xqgs";

const MessagingContext = React.createContext({});

const { Provider } = MessagingContext;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type MessagePayload = {
  notification: {
    click_action: string;
    title: string;
    body: string;
  };
  data: {
    id: string;
    url: string;
    path: string;
  };
};

const MessagingProvider = ({ children }: { children: any }) => {
  const navigate = useNavigate();

  const onTokenReceived = (token: string) => {
    httpsCallable(getFunctions(), "onSubscribe")({ token }).catch(console.log);
  };

  const onTokenError = (error: FirebaseError) => {
    if (
      error.hasOwnProperty("code") &&
      error.code === "messaging/permission-default"
    )
      console.log("You need to allow the site to send notifications");
    else if (
      error.hasOwnProperty("code") &&
      error.code === "messaging/permission-blocked"
    )
      console.log(
        "Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings",
      );
    else console.log("Unable to subscribe you to notifications");
  };

  useEffect(() => {
    getToken(getMessaging(), { vapidKey })
      .then((token) => onTokenReceived(token))
      .catch(onTokenError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onMessage(getMessaging(), (payload) => {
      console.log("onMessage: ", payload);

      const key = `open${Date.now()}`;

      const onClick = () => {
        notification.close(key);

        const pathname = payload?.data?.path;

        if (!pathname) {
          return navigate("/notifications");
        }

        return navigate(`/${pathname}`);
      };

      const btn = (
        <Button type="primary" size="small" onClick={onClick}>
          Preview
        </Button>
      );

      notification.open({
        message: payload?.notification?.title,
        description: payload?.notification?.body,
        btn,
        key,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Provider value={{}}>{children}</Provider>;
};

export { MessagingProvider };

export default MessagingContext;
