import React, { useCallback, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";

import { Form, Layout, Input, Button, message, Row, Col } from "antd";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { useNavigate, useLocation } from "react-router-dom";

import WebView from "../../../components/WebView";
import PageHeader from "../../../components/PageHeader";

const TaskCreate = React.memo(() => {
  const { t } = useTranslation(["taskRepeatInterval"]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { mutate: onCreate, isLoading } = useMutation(
    (values: InputTask) =>
      httpsCallable(getFunctions(), "onCreateTask")(values),
    {
      onSuccess: () => {
        message.success(
          "Query created. Out system will soon schedule your task",
        );

        navigate("/tasks");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const [previewURL, setPreviewURL] = useState();

  const [form] = Form.useForm<InputTask>();

  const onSubmit = useCallback((values: InputTask) => {
    onCreate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMessage = useCallback(
    (e: MessageEvent<{ type: string; data: never }>) => {
      if (e.data?.type === "selector") {
        console.log(e?.data);
        message.info(`Item selected`);

        const prev = form.getFieldsValue();

        form.setFieldsValue({
          ...prev,
          attributes: {
            ...prev?.attributes,
            // @ts-expect-error
            selector: e.data.data?.selector,
          },
        });
      }
    },
    [form],
  );

  return (
    <>
      <PageHeader
        title={t("task:title")}
        breadcrumb={{
          routes: [
            {
              path: "/tasks",
              breadcrumbName: t("tasks:title"),
            },
            {
              breadcrumbName: t("task:title"),
              path: pathname,
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Row gutter={32}>
          <Col xs={24} sm={24} md={12} xxl={8}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{}}
              requiredMark="optional"
              onFinish={onSubmit}
            >
              <Form.Item
                label="URL"
                name={["attributes", "url"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    onClick={() =>
                      setPreviewURL(form.getFieldValue(["attributes", "url"]))
                    }
                  >
                    Preview
                  </Button>
                )}
              </Form.Item>

              <Form.Item>
                <Button disabled={isLoading} type="primary" htmlType="submit">
                  {t("task:button.create")}
                </Button>
              </Form.Item>
            </Form>
          </Col>

          <Col xs={24} sm={24} md={12} xxl={16}>
            {previewURL && <WebView url={previewURL} onMessage={onMessage} />}
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
});

export default TaskCreate;
