import React, { useCallback, useMemo } from "react";

import { getAuth } from "firebase/auth";
import {
  collection,
  getFirestore,
  query,
  limit,
  startAfter,
} from "firebase/firestore";

import flatMapDeep from "lodash/flatMapDeep";

import { useTranslation } from "react-i18next";

import { List, Layout, Button } from "antd";
import { Route, Routes, Link } from "react-router-dom";
import { useFirestoreInfiniteQuery } from "@react-query-firebase/firestore";

import PageHeader from "../../components/PageHeader";
import NotificationListItem from "../../components/NotificationListItem";

import { useQueryCurrentUser } from "../../context/Auth";

import NotificationPage from "./Notification";

const Notifications = React.memo(() => {
  const { t } = useTranslation(["notifications"]);

  const { data: user } = useQueryCurrentUser();

  const notificationsQuery = query(
    collection(
      getFirestore(),
      `users/${getAuth().currentUser?.uid}/notifications`,
    ),
    limit(10),
  );

  const {
    data: queryData,
    isLoading,
    fetchNextPage,
  } = useFirestoreInfiniteQuery(
    [`notifications`],
    notificationsQuery,
    (snapshot) => {
      const lastDocument = snapshot.docs[snapshot.docs.length - 1];

      if (!lastDocument) {
        return undefined;
      }

      // Get the next 20 documents starting after the last document fetched.
      return query(notificationsQuery, startAfter(lastDocument));
    },
  );

  const notifications = useMemo(
    () =>
      flatMapDeep(queryData?.pages, (snapshot) =>
        snapshot.docs.map(
          (d) => ({ ...d.data(), id: d.id } as IUserNotification),
        ),
      ),
    [queryData?.pages],
  );

  const onBeforeLoadMore = useCallback(() => {
    if (isLoading) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, isLoading]);

  return (
    <>
      <PageHeader
        title={t("notifications:title")}
        breadcrumb={{
          routes: [
            {
              path: "/",
              breadcrumbName: t("notifications:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item) => {
            const read = !user?.unreadNotifications?.includes(item.id);

            return (
              <Link key={item.id} to={`/notifications/${item.id}`}>
                <NotificationListItem item={item} read={read} />
              </Link>
            );
          }}
          footer={
            <Button loading={isLoading} onClick={onBeforeLoadMore}>
              {t("notifications:button.fetchNextPage")}
            </Button>
          }
        />
      </Layout.Content>
    </>
  );
});

const NotificationsRouter = () => {
  return (
    <Routes>
      <Route index element={<Notifications />} />
      <Route path=":notificationId" element={<NotificationPage />} />
    </Routes>
  );
};

export default NotificationsRouter;
