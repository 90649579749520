import { useMemo } from "react";

import map from "lodash/map";

import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryUserRunners } from "../../../../lib/hooks/useQueryUserRunners";

interface RunnerProps {
  userId?: string;
}

const FormItemRunner = (props: RunnerProps) => {
  const { userId } = props;

  const { t } = useTranslation(["task"]);

  const { data, isLoading: loading } = useQueryUserRunners(userId);

  const options = useMemo(
    () =>
      map(data, ({ id, modelName, displayName }) => ({
        key: id,
        value: id,
        label: displayName || modelName,
      })),
    [data],
  );

  return (
    <Form.Item
      label={t("task:input.label.runner")}
      name="runner"
      extra={t("task:input.helper.runner")}
      rules={[{ required: false }]}
    >
      <Select options={options} loading={loading} allowClear />
    </Form.Item>
  );
};

export default FormItemRunner;
