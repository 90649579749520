import React, { useEffect } from "react";

import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";

import { useAuthIdToken, useAuthUser } from "@react-query-firebase/auth";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { Col, Layout, Row, Spin } from "antd";

const auth = getAuth();

export interface AuthClaims {
  role?: "admin" | "customer";
}

export const useQueryAuthUser = () => useAuthUser(["user"], auth);

export const useQueryCurrentUser = () => {
  const { data: authUser } = useQueryAuthUser();

  return useFirestoreDocumentData<IUser>(
    [`users/${authUser?.uid}`],
    doc(getFirestore(), `users/${authUser?.uid}`).withConverter({
      toFirestore(data) {
        return data;
      },
      fromFirestore(snapshot): IUser {
        const data = snapshot.data() as IUser;

        return {
          ...data,
          id: snapshot.id,
        };
      },
    }),
    {
      subscribe: true,
    },
    { enabled: !!authUser },
  );
};

export const useQueryAuthIdToken = () => useAuthIdToken(["token"], auth);

export const useAuthClaims = () => {
  const { data } = useQueryAuthIdToken();

  return data?.claims;
};

export const useHasRole = (role: AuthClaims["role"]) => {
  const claims = useAuthClaims();

  const userRole = claims?.role ?? "customer";

  return userRole === role;
};

export const AuthProvider: React.FC = ({ children }) => {
  const { isLoading, isFetching } = useQueryAuthUser();

  const { isLoading: isLoadingToken, isFetching: isFetchingToken } =
    useQueryAuthIdToken();

  useEffect(() => {
    dayjs.locale("sr");
  }, []);

  const loading = isLoading || isFetching || isLoadingToken || isFetchingToken;

  if (loading) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Row justify="center" align="middle" style={{ flex: 1 }}>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      </Layout>
    );
  }

  return <>{children}</>;
};
