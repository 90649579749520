import React from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";

import PageHeader from "../../../components/PageHeader";

import { UserTasksTable } from "../../Tasks/Tasks";

const TasksPage = React.memo(() => {
  const params = useParams<{ userId: string }>();

  const { t } = useTranslation(["tasks"]);

  return (
    <>
      <PageHeader
        title={t("tasks:title")}
        breadcrumb={{
          routes: [
            {
              path: "..",
              breadcrumbName: t("users:title"),
            },
            {
              path: ".",
              breadcrumbName: t("user:title"),
            },
            {
              path: ".",
              breadcrumbName: t("tasks:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <UserTasksTable userId={params?.userId} />
      </Layout.Content>
    </>
  );
});

export default TasksPage;
