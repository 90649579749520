import React, { useMemo } from "react";

import {
  getFirestore,
  collection,
  query,
  orderBy,
  FirestoreDataConverter,
} from "firebase/firestore";

import map from "lodash/map";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, Space, Table, Button, Popover, Typography } from "antd";
import {
  LinkOutlined,
  AppleOutlined,
  CloudOutlined,
  AndroidOutlined,
} from "@ant-design/icons";

import { useFirestoreQueryData } from "@react-query-firebase/firestore";

import type { ColumnsType } from "antd/lib/table";

import PageHeader from "../../../components/PageHeader";

import { CONSOLE_HOST, TaskTypeOptions } from "../../../config/const";

const { Text } = Typography;

const postConverter: FirestoreDataConverter<
  WithIdField<TaskConfiguration, "id">
> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as TaskConfiguration;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

interface PopoverContestProps {
  runner?: Record<string, boolean>;
}

const PopoverContest = React.memo((props: PopoverContestProps) => (
  <Space direction="vertical">
    {map(props?.runner, (value, key) => (
      <Text key={key} type={value ? "success" : "danger"}>
        {key}
      </Text>
    ))}
  </Space>
));

const useTableColumns = (): ColumnsType<
  WithIdField<TaskConfiguration, "id">
> => {
  const { t } = useTranslation(["taskConfigurations"]);

  return [
    // {
    //   dataIndex: "id",
    //   title: "Id",
    //   render: (value) => {
    //     return <Link to={`/users/${value}`}>{value}</Link>;
    //   },
    // },
    {
      dataIndex: ["attributes", "name"],
      title: t("taskConfigurations:table.header.attributes.name"),
      render: (value, record) => (
        <a href={record?.attributes?.url} target="_blank" rel="noreferrer">
          {value}
        </a>
      ),
    },
    {
      dataIndex: ["taskType"],
      title: t("taskConfigurations:table.header.taskType"),
      filters: TaskTypeOptions.map((value) => ({
        text: t(`task:select.option.type.option.${value}.label`),
        value,
      })),
      onFilter: (value, record) => record?.type === value,
      render: (value) => t(`task:select.option.type.option.${value}.label`),
    },
    {
      dataIndex: "runners",
      title: "Runners",
      render: (_value, record) => {
        return (
          <Space align="center" direction="horizontal">
            <Popover
              trigger="hover"
              content={<PopoverContest runner={record?.runners?.cloud} />}
            >
              <CloudOutlined />
            </Popover>
            <Popover
              trigger="hover"
              content={<PopoverContest runner={record?.runners?.android} />}
            >
              <AndroidOutlined />
            </Popover>
            <Popover
              trigger="hover"
              content={<PopoverContest runner={record?.runners?.ios} />}
            >
              <AppleOutlined />
            </Popover>
          </Space>
        );
      },
    },
    {
      dataIndex: "id",
      title: t("users:table.header.actions"),
      render: (_: any, record) => (
        <Space>
          <Button
            href={`${CONSOLE_HOST}/firestore/data/taskConfigurations/${record.id}`}
            target="_blank"
            rel="noreferrer"
            type="link"
            icon={<LinkOutlined />}
            title="Opens user document in firestore"
          >
            {t("taskConfigurations:button.open")}
          </Button>

          <Link to={record.id}>
            <Button type="primary">
              {t("taskConfigurations:button.view")}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
};

const UsersPage = React.memo(() => {
  const { t } = useTranslation(["taskConfigurations"]);

  const collectionRef = useMemo(
    () =>
      query(
        collection(getFirestore(), `taskConfigurations`).withConverter(
          postConverter,
        ),
        orderBy("order", "asc"),
      ),
    [],
  );

  const { data: users, isLoading: loading } = useFirestoreQueryData(
    ["taskConfigurations"],
    collectionRef,
    {
      subscribe: true,
    },
  );

  const columns = useTableColumns();

  return (
    <>
      <PageHeader
        title={t("taskConfigurations:title")}
        breadcrumb={{
          routes: [
            {
              path: ".",
              breadcrumbName: t("taskConfigurations:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Table
          loading={loading}
          columns={columns}
          dataSource={users ?? []}
          rowKey="id"
          scroll={{ x: true }}
        />
      </Layout.Content>
    </>
  );
});

export default UsersPage;
