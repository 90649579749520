import { Descriptions } from "antd";

const TaskProductPrice = (props: TaskDocument) => {
  const { payload } = props as TaskDocumentProducts;

  return (
    <Descriptions bordered layout="horizontal" column={2}>
      <Descriptions.Item label="Count">{payload?.count}</Descriptions.Item>
    </Descriptions>
  );
};

export default TaskProductPrice;
