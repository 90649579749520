import React from "react";
import { Navigate } from "react-router-dom";

import { AuthClaims, useHasRole } from "../../context/Auth";

export interface Props {
  role: AuthClaims["role"];
  redirectTo?: string;
}

const RoleRoute = (props: React.PropsWithChildren<Props>) => {
  const { children, role, redirectTo = "/" } = props;

  const hasRole = useHasRole(role);

  if (hasRole) {
    return <>{children}</>;
  }

  return <Navigate to={redirectTo} />;
};

const MemoRoleRoute = React.memo(RoleRoute);

export default MemoRoleRoute;
