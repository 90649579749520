import { useMemo } from "react";

import dayjs from "dayjs";

import { List, Badge, Typography } from "antd";

import { useTranslation } from "react-i18next";

const { Text } = Typography;

interface NotificationListItemProps {
  item: IUserNotification;
  read: boolean;
}

const NotificationListItem = (props: NotificationListItemProps) => {
  const { t } = useTranslation(["notification"]);

  const {
    read,
    item: {
      createdAt,
      data: rawData,
      notification: { title, body },
    },
  } = props;

  const data = useMemo(() => {
    if (!rawData?.data) {
      return {};
    }

    try {
      return JSON.parse(rawData?.data);
    } catch {
      return {};
    }
  }, [rawData?.data]);

  return (
    <List.Item
      style={{ position: "relative" }}
      extra={
        <div style={{ position: "absolute", right: 0, top: 0 }}>
          <Badge dot={!read} />
        </div>
      }
    >
      <List.Item.Meta title={t(title, data)} description={t(body, data)} />
      <Text style={{ fontSize: 10 }}>
        {dayjs(createdAt.toDate()).fromNow()}
      </Text>
    </List.Item>
  );
};

export default NotificationListItem;
