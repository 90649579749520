import React from "react";

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { Form, Input, Button, Row, Col, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface RegisterVariables {
  email: string;
  password: string;
}

const Register = React.memo(() => {
  const { t } = useTranslation(["login"]);

  const onSubmit = (variables: RegisterVariables) => {
    createUserWithEmailAndPassword(
      getAuth(),
      variables.email,
      variables.password,
    )
      .then(() => {
        message.success("Success");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      className="login-layout bg-white vh-100"
    >
      <Col xs={20} sm={14} md={10} xl={6} xxl={4}>
        <Form
          name="register"
          layout="vertical"
          initialValues={{
            email: "",
            password: "",
          }}
          requiredMark="optional"
          onFinish={onSubmit}
        >
          <Form.Item
            name="email"
            label={t("register:input.label.email")}
            rules={[
              {
                required: true,
                message: t("register:input.error.email.required"),
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 20 }}
            name="password"
            label={t("register:input.label.password")}
            rules={[
              {
                required: true,
                message: t("register:input.error.password.required"),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              type="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-100">
              {t("register:button.submit")}
            </Button>
          </Form.Item>
        </Form>
        <Link
          to={{
            pathname: "/login",
          }}
        >
          <Button type="default" htmlType="submit" className="w-100">
            {t("register:button.login")}
          </Button>
        </Link>
      </Col>
    </Row>
  );
});

export default Register;
