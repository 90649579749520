import { getFunctions, httpsCallable } from "firebase/functions";

import { Button, message } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

const TaskScheduleButton = ({
  task,
}: {
  task: WithIdField<TaskDocument, "id">;
}) => {
  const { t } = useTranslation(["tasks"]);

  const { mutateAsync: onRequestSchedule, isLoading: loading } = useMutation(
    () => {
      return httpsCallable(
        getFunctions(),
        "onRunTask",
      )({
        path: `users/${task.createdBy}/tasks/${task.id}`,
      });
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <Button
      type="primary"
      icon={<SyncOutlined />}
      loading={loading}
      onClick={() => onRequestSchedule()}
    >
      {t("tasks:button.run")}
    </Button>
  );
};

export default TaskScheduleButton;
