import React, { useMemo } from "react";

import { getAuth } from "firebase/auth";
import { doc, deleteDoc, getFirestore } from "firebase/firestore";

import queryString from "query-string";
import {
  Row,
  Col,
  Layout,
  Typography,
  List,
  Avatar,
  Button,
  Skeleton,
  Space,
  Alert,
  Popconfirm,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import PageHeader from "../../components/PageHeader";

import { useSlack } from "../../lib/hooks/useSlack";
import { useQueryUser } from "../../lib/hooks/useQueryUser";

const { Title } = Typography;

export const SlackIntegrationResponse = () => {
  const { data: user } = useQueryUser();

  const location = useLocation();

  const query = useMemo(
    () => queryString.parse(location.search, { parseBooleans: true }),
    [location.search],
  );

  if (!user) {
    return null;
  }

  if (typeof query?.integrations_slack !== "boolean") {
    return null;
  }

  if (query?.integrations_slack) {
    return (
      <Alert
        message="Success!"
        description="Slack app has been integrated successfully."
        type="success"
        showIcon
      />
    );
  }

  return (
    <Alert
      message="Something went wrong"
      description="Failed to integrate slack application"
      type="error"
      showIcon
    />
  );
};

const SlackIntegrationButton = () => {
  const { t } = useTranslation(["settings"]);

  const slack = useSlack();

  const queryClient = useQueryClient();

  const { data: user } = useQueryUser({ source: "default" });

  const { mutateAsync, isLoading: isDeleting } = useMutation(
    () => {
      return deleteDoc(
        doc(getFirestore(), `slack/${getAuth().currentUser?.uid}`),
      );
    },
    {
      onSuccess: () => {
        queryClient.setQueryData<IUser | undefined>(
          [`users/${getAuth().currentUser?.uid}`],
          (user) => {
            if (!user) {
              return undefined;
            }

            return {
              ...user,
              integrations: {
                ...user?.integrations,
                slack: false,
              },
            };
          },
        );
      },
    },
  );

  if (!user?.integrations?.slack) {
    return (
      <a href={slack.url}>
        <Button type="primary">{t("settings:section.button.connect")}</Button>
      </a>
    );
  }

  return (
    <Space>
      <a href={slack.url}>
        <Button type="primary">{t("settings:section.button.reconnect")}</Button>
      </a>
      <Popconfirm
        placement="topLeft"
        title={t("settings:section.popup.disconnect.title")}
        onConfirm={() => mutateAsync()}
        okText={t("settings:section.popup.disconnect.button.confirm")}
        okType="danger"
        cancelText={t("settings:section.popup.disconnect.button.dismiss")}
        disabled={isDeleting}
      >
        <Button
          type="primary"
          danger
          icon={<CloseOutlined />}
          loading={isDeleting}
        />
      </Popconfirm>
    </Space>
  );
};

const Settings = React.memo(() => {
  const { t } = useTranslation(["settings"]);

  const { isLoading } = useQueryUser();

  return (
    <>
      <PageHeader
        title={t("settings:title")}
        breadcrumb={{
          routes: [
            {
              path: "/settings",
              breadcrumbName: t("settings:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Row gutter={32}>
          <Col xs={24} sm={24} md={12} xxl={8}>
            <Title level={2}>{t("settings:section.integrations.title")}</Title>
            {/* <SlackIntegrationResponse /> */}
            <Skeleton loading={isLoading}>
              <List.Item extra={<SlackIntegrationButton />}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{ background: "#fff" }}
                      shape="square"
                      size={40}
                      src="/images/slack_logo.png"
                    />
                  }
                  title={
                    <Title level={4} style={{ marginBottom: 0 }}>
                      Slack
                    </Title>
                  }
                />
              </List.Item>
            </Skeleton>
          </Col>
          <Col xs={24} sm={24} md={12} xxl={8} />
        </Row>
      </Layout.Content>
    </>
  );
});

export default Settings;
