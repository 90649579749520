/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from "react";
import {
  Button,
  Space,
  Table,
  Tooltip,
  message,
  Tag,
  TagProps,
  Typography,
  Popconfirm,
} from "antd";
import { useTranslation } from "react-i18next";
import type { ColumnsType, TableProps } from "antd/lib/table";

import qs from "query-string";
import last from "lodash/last";

import dayjs from "dayjs";

import {
  getFirestore,
  doc,
  query,
  limit,
  where,
  orderBy,
  updateDoc,
  collection,
  startAfter,
  QueryDocumentSnapshot,
  DocumentReference,
  deleteDoc,
  QueryConstraint,
  QuerySnapshot,
} from "firebase/firestore";

import { useFirestoreQuery } from "@react-query-firebase/firestore";

import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";

import {
  DownloadOutlined,
  LeftOutlined,
  LinkOutlined,
  RightOutlined,
  SyncOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";

import { CONSOLE_HOST } from "../../config/const";

const { Text } = Typography;

const DELIVERY_STATE_COLOR: { [key in DeliveryState]: TagProps["color"] } = {
  PENDING: "default",
  ERROR: "error",
  PROCESSING: "processing",
  RETRY: "yellow",
  SUCCESS: "success",
};

const useTableColumns = (
  query?: any,
): ColumnsType<DeliveryBase & { id: string }> => {
  const { t } = useTranslation(["users"]);

  return [
    {
      dataIndex: "id",
      title: "id",
    },
    {
      dataIndex: ["delivery", "state"],
      title: t("delivery:table.header.delivery.state"),
      filters: ["PENDING", "PROCESSING", "RETRY", "SUCCESS", "ERROR"].map(
        (value) => ({ text: value, value }),
      ),
      onFilter: (value, record) => record?.delivery?.state === value,
      filteredValue: query?.state,
      render: (value: DeliveryState, record) => {
        const tooltipValue = record?.delivery?.error ?? record?.delivery?.info;

        return (
          <Tooltip placement="bottomLeft" title={tooltipValue}>
            <Tag
              color={DELIVERY_STATE_COLOR[value]}
              icon={value === "PROCESSING" ? <SyncOutlined spin /> : undefined}
            >
              {value}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: ["delivery", "attempts"],
      title: t("delivery:table.header.delivery.attempts"),
    },
    {
      dataIndex: ["delivery", "endTime"],
      title: t("delivery:table.header.delivery.endTime"),
      render: (value: FirestoreTimestamp) => {
        return dayjs(value.toDate()).format("DD/MM/YYYY HH:mm");
      },
    },
  ];
};

const DeliveryDownload = (props: { item: DeliveryBase & { id: string } }) => {
  const { item } = props;

  const { t } = useTranslation(["delivery"]);

  const href = useMemo(() => {
    let content = "data:text/json;charset=utf-8,";

    content += encodeURIComponent(JSON.stringify(item, undefined, "\t"));

    return content;
  }, [item]);

  return (
    <Button
      href={href}
      download={`${item.id}.json`}
      type="link"
      icon={<DownloadOutlined />}
      title="Download task document"
    >
      {t("delivery:button.download")}
    </Button>
  );
};

const DeliveryRetry = React.memo(
  (props: { documentRef: DocumentReference }) => {
    const { documentRef } = props;

    const { t } = useTranslation(["delivery"]);

    const { mutateAsync: onRequestSchedule, isLoading: loading } = useMutation(
      () => {
        return updateDoc(documentRef, {
          "delivery.state": "RETRY",
        });
      },
      {
        onError: (error: Error) => {
          message.error(error.message);
        },
      },
    );

    return (
      <Button
        type="primary"
        icon={<SyncOutlined />}
        loading={loading}
        onClick={() => onRequestSchedule()}
      >
        {t("delivery:button.run")}
      </Button>
    );
  },
);

const DeliveryDelete = React.memo(
  (props: { documentRef: DocumentReference }) => {
    const { documentRef } = props;

    const { t } = useTranslation(["delivery"]);

    const { mutateAsync: onDelete, isLoading: loading } = useMutation(
      () => {
        return deleteDoc(documentRef);
      },
      {
        onError: (error: Error) => {
          message.error(error.message);
        },
      },
    );

    return (
      <Popconfirm
        placement="topLeft"
        title="Are you sure you want to delete this task?"
        onConfirm={() => onDelete()}
        okText="Delete"
        okType="danger"
        cancelText="Cancel"
        disabled={loading}
      >
        <Button type="primary" danger loading={loading}>
          {t("delivery:button.delete")}
        </Button>
      </Popconfirm>
    );
  },
);

// interface PaginationResponse {
//   total: number;
//   estimatedTotal: number;
//   start: () => void;
//   previous: () => void;
//   next: () => void;
//   end: () => void;
// }

// const useTableHook = <R, T extends TableProps<R>>(
//   collectionData: QuerySnapshot<R> | undefined,
// ): [
//   {
//     onChange: T["onChange"];
//   },
//   PaginationResponse,
// ] => {
//   const size = 10;
//   const [filter, setFilter] = useState<any>();

//   const [pagination, setPagination] = useState<{
//     cursors: (QueryDocumentSnapshot<DeliveryBase> | undefined)[];
//     index: number;
//     total?: number;
//   }>({
//     index: 0,
//     cursors: [undefined],
//   });

//   const paginationResponse = useMemo(() => {
//     const hasNextPage = collectionData?.size === size;
//     const estimatedTotal =
//       (collectionData?.size ?? 0) + (pagination.cursors.length - 1) * size;

//     return {
//       total: pagination?.total ?? 0,
//       estimatedTotal,
//       start: () => setPagination((prev) => ({ ...prev, index: 0 })),
//       previous: () =>
//         setPagination((prev) => ({ ...prev, index: prev.index - 1 })),
//       next: () => {
//         const lastCursor = last(collectionData?.docs);

//         if (!lastCursor) {
//           return;
//         }

//         setPagination((prev) => {
//           const nextIndex = prev.index + 1;

//           if (prev.cursors[nextIndex]) {
//             return { ...prev, index: nextIndex };
//           }

//           return {
//             index: nextIndex,
//             cursors: [...prev.cursors, lastCursor],
//           };
//         });
//       },
//       end: () => {
//         setPagination((prev) => ({
//           ...prev,
//           index: prev.cursors.length - 1,
//         }));
//       },
//     };
//   }, [
//     collectionData?.docs,
//     collectionData?.size,
//     pagination.cursors.length,
//     pagination?.total,
//   ]);

//   return [
//     {
//       onChange: (_pagination, filters) => {
//         setFilter(filters);

//         setPagination({ cursors: [undefined], index: 0 });
//       },
//     },
//     paginationResponse,
//   ];
// };

const DeliveryTable = (props: { collection: string }) => {
  const { t } = useTranslation(["delivery"]);

  const { collection: collectionName } = props;

  const columns = useTableColumns();

  const extendedColumns: ColumnsType<DeliveryBase & { id: string }> =
    useMemo(() => {
      const nextColumns = [...columns];

      if (collectionName === "sendgrid_mail") {
        nextColumns.push({
          dataIndex: "to",
          title: "Receiver",
        });
      }

      nextColumns.push({
        // fixed: "right",
        dataIndex: "id",
        title: t("delivery:table.actions"),
        render: (_value, record) => (
          <Space>
            <Button
              href={`${CONSOLE_HOST}/firestore/data/${collectionName}/${record?.id}`}
              target="_blank"
              rel="noreferrer"
              type="link"
              icon={<LinkOutlined />}
              title="Opens task document in firestore"
            >
              {t("delivery:button.open")}
            </Button>
            <DeliveryDownload item={record} />
            <DeliveryRetry
              documentRef={doc(
                getFirestore(),
                `${collectionName}/${record?.id}`,
              )}
            />
            <DeliveryDelete
              documentRef={doc(
                getFirestore(),
                `${collectionName}/${record?.id}`,
              )}
            />
          </Space>
        ),
      });

      return nextColumns;
    }, [collectionName, columns, t]);

  // const { search } = useLocation();

  // const searchQuery = useMemo(
  //   () => qs.parse(search, { parseBooleans: true }),
  //   [search],
  // );

  // console.log(searchQuery);

  const [filter, setFilter] = useState<any>();
  const size = 10;

  const [pagination, setPagination] = useState<{
    cursors: (QueryDocumentSnapshot<DeliveryBase> | undefined)[];
    index: number;
    total?: number;
  }>({
    index: 0,
    cursors: [undefined],
  });

  const cursor = pagination?.cursors?.[pagination?.index];

  const collectionQuery = useMemo(() => {
    const collectionRef = collection(getFirestore(), collectionName);

    const constrains: QueryConstraint[] = [
      orderBy("delivery.startTime", "desc"),
    ];

    if (cursor) {
      constrains.push(startAfter(cursor));
    }

    if (
      Array.isArray(filter?.["delivery.state"]) &&
      filter?.["delivery.state"].length > 0
    ) {
      constrains.push(where("delivery.state", "in", filter["delivery.state"]));
    }

    constrains.push(limit(size));

    return query(collectionRef, ...constrains);
  }, [collectionName, cursor, filter]);

  const {
    data: collectionData,
    isLoading,
    isPreviousData,
  } = useFirestoreQuery(
    [collectionName, { cursor, filter }],
    collectionQuery,
    {
      subscribe: false,
    },
    {
      retry: false,
      keepPreviousData: true,
      onSuccess: (snapshot) => {
        if (snapshot.size !== size) {
          setPagination((prev) => ({
            ...prev,
            total: snapshot.size + (prev.cursors.length - 1) * size,
          }));
        }
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const data = useMemo(
    () =>
      collectionData?.docs.map((snapshot) => ({
        ...snapshot.data(),
        id: snapshot.id,
      })),
    [collectionData?.docs],
  );

  const hasNextPage = collectionData?.size === size;
  const estimatedTotal =
    (collectionData?.size ?? 0) + (pagination.cursors.length - 1) * size;

  const total = pagination?.total ?? estimatedTotal;

  return (
    <>
      <Table
        rowKey="id"
        loading={isLoading || isPreviousData}
        columns={extendedColumns}
        dataSource={data}
        pagination={false}
        scroll={{ x: true }}
        onChange={(_pagination, filters) => {
          setFilter(filters);
          setPagination({ cursors: [undefined], index: 0 });
        }}
      />
      <Space
        align="center"
        direction="horizontal"
        style={{
          paddingTop: 16,
          paddingBottom: 16,
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Text>
          {`${pagination.index * size + 1} to ${total} of ${
            pagination?.total ? pagination.total : "more"
          }`}
        </Text>
        <Space align="center" direction="horizontal">
          <Button
            type="default"
            size="small"
            icon={<VerticalRightOutlined />}
            disabled={pagination.index === 0 || isLoading}
            onClick={() => setPagination((prev) => ({ ...prev, index: 0 }))}
          />
          <Button
            type="default"
            size="small"
            icon={<LeftOutlined />}
            disabled={pagination.index === 0 || isLoading}
            onClick={() => {
              setPagination((prev) => ({ ...prev, index: prev.index - 1 }));
            }}
          />
          <Text>
            {`Page ${pagination.index + 1} of ${
              pagination?.total ? pagination.cursors.length : "more"
            }`}
          </Text>
          <Button
            type="default"
            size="small"
            icon={<RightOutlined />}
            disabled={!hasNextPage || isLoading}
            onClick={() => {
              const lastCursor = last(collectionData?.docs);

              if (!lastCursor) {
                return;
              }

              setPagination((prev) => {
                const nextIndex = prev.index + 1;

                if (prev.cursors[nextIndex]) {
                  return { ...prev, index: nextIndex };
                }

                return {
                  index: nextIndex,
                  cursors: [...prev.cursors, lastCursor],
                };
              });
            }}
          />
          <Button
            type="default"
            size="small"
            icon={<VerticalLeftOutlined />}
            disabled={
              !(
                typeof pagination.total === "number" &&
                pagination.total > 0 &&
                hasNextPage
              )
            }
            onClick={() => {
              setPagination((prev) => ({
                ...prev,
                index: prev.cursors.length - 1,
              }));
            }}
          />
        </Space>
      </Space>
    </>
  );
};

export default DeliveryTable;
