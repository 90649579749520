import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, getDocFromCache } from "firebase/firestore";

import { useQuery } from "react-query";

const useQueryUser = (options?: { source: "cache" | "default" }) => {
  return useQuery(
    [`users/${getAuth().currentUser?.uid}`],
    ({ queryKey: [documentPath] }) => {
      const ref = doc(getFirestore(), documentPath);

      if (options?.source === "cache") {
        return getDocFromCache(ref).then(
          (snapshot) => snapshot.data() as IUser,
        );
      }

      return getDoc(ref).then((snapshot) => snapshot.data() as IUser);
    },
  );
};

export { useQueryUser };
