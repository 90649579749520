import { Routes, Route } from "react-router-dom";

import Recipe from "./Recipe";
import Recipes from "./Recipes";
import RecipeCreate from "./RecipeCreate";

const RecipesRouter = () => {
  return (
    <Routes>
      <Route index element={<Recipes />} />
      <Route path="new" element={<RecipeCreate />} />
      <Route path="new/test" element={<Recipe />} />
    </Routes>
  );
};

export default RecipesRouter;
