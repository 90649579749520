import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useQueryAuthUser } from "../../context/Auth";

interface Props {
  redirect?: string;
}

const PublicRoute = ({
  children,
  redirect,
}: React.PropsWithChildren<Props>) => {
  const location: { state?: any } = useLocation();

  const pathname = redirect || location?.state?.from?.pathname || "/";

  const { data: user } = useQueryAuthUser();

  if (user) {
    return <Navigate to={pathname} />;
  }

  return <>{children}</>;
};

const MemoPublicRoute = React.memo(PublicRoute);

export default MemoPublicRoute;
