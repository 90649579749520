import { emulatorConfig } from "../lib/firebase";

export const TaskStatus = {
  IN_PROGRESS: "progress",
  SCHEDULED: "scheduled",
  FAILED: "failed",
  PAUSED: "paused",
};

export const TaskStatusOptions: TaskDocumentStatus[] = [
  "scheduled",
  "progress",
  "finished",
  "paused",
  "failed",
];

export const TaskTypeOptions: TaskType[] = [
  "taskProducts",
  "taskProductPrice",
  "taskParkingTicket",
];

export const TASK_REPEAT_INTERVAL = [
  "*/10 * * * *",
  "*/15 * * * *",
  "*/30 * * * *",
  "0 * * * *",
  "0 */2 * * *",
  "0 */3 * * *",
  "0 */4 * * *",
  "0 */6 * * *",
  "0 */8 * * *",
  "0 */12 * * *",
  "0 0 * * *",
  "0 9 * * *",
  "0 12 * * *",
  "0 17 * * *",
  "0 0 * * MON",
  "0 0 * * TUE",
  "0 0 * * WED",
  "0 0 * * THU",
  "0 0 * * FRI",
  "0 0 * * SAT",
  "0 0 * * SUN",
];

// prettier-ignore
export const CONSOLE_HOST = emulatorConfig.firestore ? "http://localhost:4000": "https://console.firebase.google.com/u/1/project/advertiser-33959";
