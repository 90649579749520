import { useMemo, useCallback } from "react";

import { Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import {
  DashboardOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  RocketOutlined,
  UserOutlined,
  MailOutlined,
  PartitionOutlined,
} from "@ant-design/icons";

import useMedia from "react-use/lib/useMedia";

import { useSidebarState } from "../../../context/Layout";

import { useAuthClaims } from "../../../context/Auth";

const SidebarNavLink = (props: NavLinkProps) => {
  const [, setCollapsed] = useSidebarState();

  const isSmall = useMedia("(max-width: 767px)");

  const onClick = useCallback(() => {
    if (isSmall) {
      setCollapsed(true);
    }
  }, [isSmall, setCollapsed]);

  return <NavLink {...props} onClick={onClick} />;
};

const Sidebar = () => {
  const isSmall = useMedia("(max-width: 767px)");

  const { t } = useTranslation(["sidebar"]);

  const [collapsed] = useSidebarState();

  const { pathname } = useLocation();

  const claims = useAuthClaims();

  const defaultSelectedKeys = useMemo(
    () => [`/${pathname.split("/")[1] || ""}`],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selectedKeys = useMemo(
    () => [`/${pathname.split("/")[1] || ""}`],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname],
  );

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
      trigger={null}
      width={isSmall ? "100%" : 200}
      className="site-layout-sidebar"
      theme="dark"
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedKeys}
        defaultSelectedKeys={defaultSelectedKeys}
      >
        {process.env.NODE_ENV !== "production" && (
          <Menu.Item key="/" icon={<DashboardOutlined />}>
            <SidebarNavLink to="/">{t("sidebar:dashboard")}</SidebarNavLink>
          </Menu.Item>
        )}
        <Menu.ItemGroup key="g1" title="Manage">
          <Menu.Item key="/tasks" icon={<UnorderedListOutlined />}>
            <SidebarNavLink to="/tasks">{t("sidebar:tasks")}</SidebarNavLink>
          </Menu.Item>

          {claims?.role === "admin" && (
            <>
              {process.env.NODE_ENV !== "production" && (
                <Menu.Item key="/recipes" icon={<RocketOutlined />}>
                  <SidebarNavLink to="/recipes">
                    {t("sidebar:recipes")}
                  </SidebarNavLink>
                </Menu.Item>
              )}
              <Menu.Item key="/taskConfigurations" icon={<PartitionOutlined />}>
                <SidebarNavLink to="/taskConfigurations">
                  {t("sidebar:taskConfigurations")}
                </SidebarNavLink>
              </Menu.Item>
              <Menu.Item key="/users" icon={<UserOutlined />}>
                <SidebarNavLink to="/users">
                  {t("sidebar:users")}
                </SidebarNavLink>
              </Menu.Item>
              <Menu.Item key="/delivery" icon={<MailOutlined />}>
                <SidebarNavLink to="/delivery/email">
                  {t("sidebar:delivery")}
                </SidebarNavLink>
              </Menu.Item>
            </>
          )}

          {claims?.role !== "admin" && (
            <Menu.Item key="/settings" icon={<SettingOutlined />}>
              <SidebarNavLink to="/settings">
                {t("sidebar:settings")}
              </SidebarNavLink>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
