import { Routes, Route } from "react-router-dom";

import TaskRoute from "./Task";
import TasksRoute from "./Tasks";
import TaskCreateRoute from "./TaskCreate";

const TasksRouter = () => {
  return (
    <Routes>
      <Route index element={<TasksRoute />} />
      <Route path="new" element={<TaskCreateRoute />} />
      <Route path=":taskId" element={<TaskRoute />} />
    </Routes>
  );
};

export default TasksRouter;
