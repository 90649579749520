import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useQueryAuthUser } from "../../context/Auth";

const PrivateRoute = ({ children }: React.PropsWithChildren<any>) => {
  const location = useLocation();

  const { data: user } = useQueryAuthUser();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};

const MemoPrivateRoute = React.memo(PrivateRoute);

export default MemoPrivateRoute;
