import React from "react";

import clsx from "clsx";

import { Layout } from "antd";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar";

import { LayoutProvider, useSidebarState } from "../../../context/Layout";
import { MessagingProvider } from "../../../context/Messaging";

const LayoutPrivate: React.FC = () => {
  const [collapsed] = useSidebarState();

  return (
    <Layout className="site-layout-main">
      <Sidebar />
      <Header />
      <Layout
        className={clsx("site-layout", {
          open: !collapsed,
        })}
      >
        <Outlet />
      </Layout>
    </Layout>
  );
};

const WrappedLayoutProvider: React.FC = ({ children }) => (
  <LayoutProvider>
    <MessagingProvider>
      <LayoutPrivate>{children}</LayoutPrivate>
    </MessagingProvider>
  </LayoutProvider>
);

export default WrappedLayoutProvider;
