import { Layout, Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { useParams, Link } from "react-router-dom";

import PageHeader from "../../components/PageHeader";

import DeliveryTable from "./DeliveryTable";

const { TabPane } = Tabs;

const DeliveryRouter = () => {
  const { t } = useTranslation(["delivery"]);

  const { "*": activeKey } = useParams();

  return (
    <>
      <PageHeader
        title={t("delivery:title")}
        breadcrumb={{
          routes: [
            {
              path: ".",
              breadcrumbName: t("delivery:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Tabs activeKey={activeKey} destroyInactiveTabPane>
          <TabPane key="email" tab={<Link to="email">Email</Link>}>
            <DeliveryTable collection="sendgrid_mail" />
          </TabPane>
          <TabPane key="slack" tab={<Link to="slack">Slack</Link>}>
            <DeliveryTable collection="slack_message" />
          </TabPane>
          <TabPane
            key="notification_message"
            tab={<Link to="notification_message">Notification</Link>}
          >
            <DeliveryTable collection="notification_message" />
          </TabPane>
        </Tabs>
      </Layout.Content>
    </>
  );
};

export default DeliveryRouter;
