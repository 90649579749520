import type { FirestoreDataConverter } from "firebase/firestore";

const getPostConverter = <DocumentData>(): FirestoreDataConverter<
  WithIdField<DocumentData, "id">
> => ({
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as DocumentData;

    return {
      ...data,
      id: snapshot.id,
    };
  },
});

export { getPostConverter };
