import React, { useMemo } from "react";

import {
  getFirestore,
  collection,
  collectionGroup,
  query,
  FirestoreDataConverter,
} from "firebase/firestore";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Space, Layout, TableProps, Table, Button } from "antd";

import { useFirestoreQueryData } from "@react-query-firebase/firestore";

import { LinkOutlined } from "@ant-design/icons";

import { ColumnsType } from "antd/lib/table";
import PageHeader from "../../../components/PageHeader";

import { useHasRole, useQueryAuthUser } from "../../../context/Auth";

import TaskDeleteButton from "./components/TaskDeleteButton";
import TaskStatusButton from "./components/TaskStatusButton";
import TaskScheduleButton from "./components/TaskScheduleButton";
import TasksRunButton from "./components/TasksRunButton";

import { CONSOLE_HOST } from "../../../config/const";

import { useTableColumns as useDefaultTableColumns } from "./hooks/useTableColumns";

const postConverter: FirestoreDataConverter<WithIdField<TaskDocument, "id">> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as TaskDocument;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

const TasksPageTable = (props: TableProps<WithIdField<TaskDocument, "id">>) => {
  return <Table {...props} rowKey="id" scroll={{ x: true }} />;
};

const TaskAdminActions = React.memo(
  (props: { task: WithIdField<TaskDocument, "id"> }) => {
    const { t } = useTranslation(["tasks"]);

    const { task } = props;

    return (
      <>
        <Button
          href={`${CONSOLE_HOST}/firestore/data/users/${task.createdBy}/tasks/${task.id}`}
          target="_blank"
          rel="noreferrer"
          type="link"
          icon={<LinkOutlined />}
          title="Opens task document in firestore"
        >
          {t("tasks:button.open")}
        </Button>

        <Button
          href={
            task?.payload?.resultPath
              ? `${CONSOLE_HOST}/firestore/data/${task?.payload?.resultPath}`
              : undefined
          }
          target="_blank"
          rel="noreferrer"
          type="link"
          icon={<LinkOutlined />}
          title="Opens task last execution document in firestore"
          disabled={!task?.payload?.resultPath}
        >
          {t("tasks:button.openTaskResult")}
        </Button>
      </>
    );
  },
);

const TasksPageAdmin = () => {
  const { t } = useTranslation(["tasks"]);

  const { data: tasks, isLoading: loading } = useFirestoreQueryData(
    ["tasks"],
    query(collectionGroup(getFirestore(), `tasks`)).withConverter(
      postConverter,
    ),
    {
      subscribe: true,
    },
  );

  const defaultColumns = useDefaultTableColumns();

  const columns = useMemo((): ColumnsType<WithIdField<TaskDocument, "id">> => {
    return [
      {
        dataIndex: ["createdBy"],
        title: t("tasks:table.header.owner"),
        render: (value, record) => {
          const to = `/users/${record?.createdBy}`;

          return <Link to={to}>User</Link>;
        },
      },
      ...defaultColumns,
      {
        dataIndex: "id",
        title: t("tasks:table.header.adminActions"),
        render: (_: any, record) => (
          <Space>
            <TaskAdminActions task={record} />
          </Space>
        ),
      },
      {
        dataIndex: "id",
        title: t("tasks:table.header.actions"),
        render: (_: any, record) => (
          <Space>
            <TaskScheduleButton task={record} />
          </Space>
        ),
      },
    ];
  }, [defaultColumns, t]);

  return (
    <>
      <PageHeader
        title={t("tasks:title")}
        breadcrumb={{
          routes: [
            {
              path: ".",
              breadcrumbName: t("tasks:title"),
            },
          ],
        }}
        extra={[<TasksRunButton key="run-tasks" />]}
      />
      <Layout.Content className="site-layout-content">
        <TasksPageTable
          loading={loading}
          columns={columns}
          dataSource={tasks ?? []}
        />
      </Layout.Content>
    </>
  );
};

export const UserTasksTable = (props: { userId?: string }) => {
  const { userId } = props;

  const { t } = useTranslation(["tasks"]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: user } = useQueryAuthUser();

  const isAdmin = useHasRole("admin");

  const { data: tasks, isLoading: loading } = useFirestoreQueryData(
    [`users/${userId}/tasks`],
    query(
      collection(getFirestore(), `users/${userId}/tasks`).withConverter(
        postConverter,
      ),
    ),
    {
      subscribe: true,
    },
  );

  const defaultColumns = useDefaultTableColumns();

  const columns = useMemo((): ColumnsType<WithIdField<TaskDocument, "id">> => {
    // if (user?.uid !== userId) {
    //   return [
    //     ...defaultColumns,
    //     {
    //       dataIndex: "id",
    //       title: t("tasks:table.header.actions"),
    //       render: (_: any, record) => (
    //         <Space>
    //           {isAdmin && <TaskAdminActions task={record} />}
    //           <TaskScheduleButton task={record} />
    //         </Space>
    //       ),
    //     },
    //   ];
    // }

    let nextColumns: ColumnsType<WithIdField<TaskDocument, "id">> = [
      ...defaultColumns,
      {
        dataIndex: "status",
        title: t("tasks:table.header.pause"),
        render: (_: any, record) => <TaskStatusButton task={record} />,
      },
    ];

    if (isAdmin) {
      nextColumns = [
        ...nextColumns,
        {
          dataIndex: "id",
          title: t("tasks:table.header.adminActions"),
          render: (_: any, record) => (
            <Space>
              <TaskAdminActions task={record} />
            </Space>
          ),
        },
      ];
    }

    nextColumns = [
      ...nextColumns,
      {
        dataIndex: "id",
        title: t("tasks:table.header.actions"),
        render: (_: any, record) => (
          <Space>
            <TaskScheduleButton task={record} />
            <TaskDeleteButton task={record} />
          </Space>
        ),
      },
    ];

    return nextColumns;
  }, [defaultColumns, isAdmin, t]);

  return (
    <TasksPageTable
      loading={loading}
      columns={columns}
      dataSource={tasks ?? []}
    />
  );
};

const TasksPageUser = (props: { userId?: string }) => {
  const { t } = useTranslation(["tasks"]);

  return (
    <>
      <PageHeader
        title={t("tasks:title")}
        breadcrumb={{
          routes: [
            {
              path: ".",
              breadcrumbName: t("tasks:title"),
            },
          ],
        }}
        extra={[
          <Link key="task-create" to="/tasks/new">
            <Button type="primary">{t("tasks:button.create")}</Button>
          </Link>,
        ]}
      />
      <Layout.Content className="site-layout-content">
        <UserTasksTable {...props} />
      </Layout.Content>
    </>
  );
};

const TasksPage = React.memo(() => {
  const { data: user } = useQueryAuthUser();

  const isAdmin = useHasRole("admin");

  if (isAdmin) {
    return <TasksPageAdmin />;
  }

  return <TasksPageUser userId={user?.uid} />;
});

export default TasksPage;
