import {
  Row,
  Form,
  Col,
  Checkbox,
  Popover,
  Button,
  Space,
  Typography,
} from "antd";

import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { useQueryUser } from "../../../../lib/hooks/useQueryUser";

const NotificationChannels = () => {
  const { t } = useTranslation(["task"]);

  const { data: user } = useQueryUser();

  const disabled = !user?.integrations?.slack;

  return (
    <Form.Item
      label={t("task:input.label.notificationChannels")}
      requiredMark={undefined}
    >
      <Row>
        <Col flex={1}>
          <Form.Item
            name={["notificationChannels", "email"]}
            valuePropName="checked"
          >
            <Checkbox>
              {t("task:select.option.notificationChannels.email.label")}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col flex={1}>
          {disabled ? (
            <Popover
              trigger="hover"
              title={t("task:popup.notificationChannels.slack.title")}
              content={
                <Space direction="vertical">
                  <Typography.Paragraph>
                    <Trans i18nKey="task:popup.notificationChannels.slack.content" />
                  </Typography.Paragraph>
                  <Link to="/settings">
                    <Button type="primary">
                      {t(
                        "task:popup.notificationChannels.slack.button.settings",
                      )}
                    </Button>
                  </Link>
                </Space>
              }
            >
              <Form.Item
                name={["notificationChannels", "slack"]}
                valuePropName="checked"
              >
                <Checkbox disabled={disabled}>
                  {t("task:select.option.notificationChannels.slack.label")}
                </Checkbox>
              </Form.Item>
            </Popover>
          ) : (
            <Form.Item
              name={["notificationChannels", "slack"]}
              valuePropName="checked"
            >
              <Checkbox>
                {t("task:select.option.notificationChannels.slack.label")}
              </Checkbox>
            </Form.Item>
          )}
        </Col>
        <Col flex={1}>
          <Popover
            trigger="hover"
            title={t("task:popup.notificationChannels.notification.title")}
            content={t("task:popup.notificationChannels.notification.content")}
          >
            <Form.Item
              name={["notificationChannels", "notification"]}
              valuePropName="checked"
            >
              <Checkbox>
                {t(
                  "task:select.option.notificationChannels.notification.label",
                )}
              </Checkbox>
            </Form.Item>
          </Popover>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default NotificationChannels;
