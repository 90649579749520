import { useState } from "react";

import dayjs from "dayjs";

import { useInterval } from "react-use";
import type { Timestamp } from "firebase/firestore";

const Clock = ({ value }: { value: Timestamp }) => {
  const [tick, setTick] = useState<number>(value.toMillis());

  useInterval(() => {
    setTick(tick + 1);
  }, 1000);

  return <>{dayjs(value.toMillis()).fromNow()}</>;
};

export default Clock;
