import { useMemo } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { TASK_REPEAT_INTERVAL } from "../../../../config/const";

const RepeatInterval = () => {
  const { t } = useTranslation(["task", "taskRepeatInterval"]);

  const options = useMemo(
    () =>
      TASK_REPEAT_INTERVAL.map((value) => ({
        key: value,
        value,
        label: [t(`taskRepeatInterval:${value}`), `(${value})`]
          .filter(Boolean)
          .join(" - "),
      })),
    [t],
  );

  return (
    <Form.Item
      label={t("task:input.label.repeatInterval")}
      name="repeatInterval"
      rules={[{ required: true }]}
    >
      <Select options={options} />
    </Form.Item>
  );
};

export default RepeatInterval;
