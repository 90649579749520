import {
  collection,
  query,
  limit,
  orderBy,
  getFirestore,
  FirestoreDataConverter,
} from "firebase/firestore";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { useQueryAuthUser } from "../../context/Auth";

export const postConverter: FirestoreDataConverter<
  WithIdField<IUserNotification, "id">
> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as IUserNotification;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

export const useQueryUserNotifications = () => {
  const { data: authUser } = useQueryAuthUser();

  return useFirestoreQueryData(
    ["notifications", { limit: 5 }],
    query(
      collection(getFirestore(), `users/${authUser?.uid}/notifications`),
      orderBy("createdAt", "desc"),
      limit(5),
    ).withConverter(postConverter),
    {
      subscribe: true,
    },
  );
};
