import React, { useEffect, useMemo } from "react";

import { useFirestoreDocumentData } from "@react-query-firebase/firestore";

import { MoneyCollectOutlined, LockOutlined } from "@ant-design/icons";

import { Avatar, List, Space, Layout } from "antd";
import { useParams, useLocation } from "react-router-dom";

import { arrayRemove, doc, getFirestore, updateDoc } from "firebase/firestore";

import { useQueryAuthUser, useQueryCurrentUser } from "../../../context/Auth";

import PageHeader from "../../../components/PageHeader";

const IconText = ({ icon, text }: { text: any; icon: React.ReactNode }) => (
  <Space>
    {React.createElement(icon as any)}
    {text}
  </Space>
);

const NotificationTaskProducts = (props: IUserNotification) => {
  const { data } = props;

  const results = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    return JSON.parse(data?.data)?.results as any[];
  }, [data]);

  return (
    <List
      itemLayout="vertical"
      dataSource={results}
      renderItem={(item) => (
        <List.Item
          actions={[
            <IconText
              icon={MoneyCollectOutlined}
              text={item.price}
              key="list-vertical-star-o"
            />,
            <IconText
              icon={LockOutlined}
              text={item.location}
              key="list-vertical-like-o"
            />,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={item.image} shape="square" size={64} />}
            title={
              <a href={item.url} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            }
            description={item.description}
          />
        </List.Item>
      )}
    />
  );
};

const NotificationProductPrice = (props: IUserNotification) => {
  const { data } = props;

  const { name, url, minimumPrice } = useMemo(() => {
    if (!data?.data) {
      return {};
    }

    return JSON.parse(data?.data) as any;
  }, [data]);

  return (
    <List.Item.Meta
      title={
        <a href={url} target="_blank" rel="noreferrer">
          {name}
        </a>
      }
      description={minimumPrice}
    />
  );
};

const NotificationComponent: {
  [key in TUSerNotificationType]: (props: any) => JSX.Element;
} = {
  taskProducts: NotificationTaskProducts,
  taskProductPrice: NotificationProductPrice,
};

const Notification = () => {
  const { notificationId } = useParams<{ notificationId: string }>();

  const { pathname } = useLocation();

  const { data: user } = useQueryCurrentUser();
  const { data: authUser } = useQueryAuthUser();

  const { data: notification } = useFirestoreDocumentData<IUserNotification>(
    [`users/${authUser?.uid}/notifications/${notificationId}`],
    doc(
      getFirestore(),
      `users/${authUser?.uid}/notifications/${notificationId}`,
    ).withConverter({
      toFirestore(data) {
        return data;
      },
      fromFirestore(snapshot): IUserNotification {
        const data = snapshot.data() as IUserNotification;

        return {
          ...data,
          id: snapshot.id,
        };
      },
    }),
  );

  useEffect(() => {
    const read = notificationId
      ? !user?.unreadNotifications?.includes(notificationId)
      : false;

    if (!read && authUser) {
      updateDoc(doc(getFirestore(), `users/${authUser?.uid}`), {
        unreadNotifications: arrayRemove(notificationId),
      });
    }
  }, [authUser, notificationId, user]);

  const Component = notification?.data?.type
    ? NotificationComponent[notification?.data?.type]
    : undefined;

  return (
    <>
      <PageHeader
        title="Notifications"
        breadcrumb={{
          routes: [
            {
              path: "/notifications",
              breadcrumbName: "Notifications",
            },
            {
              path: pathname,
              breadcrumbName: "Notification",
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        {Component && <Component {...notification} />}
      </Layout.Content>
    </>
  );
};

export default Notification;
