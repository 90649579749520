import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import * as AppRoute from "../components/Route";

import * as Page from "../pages";
import * as Layout from "../components/Layout";

import { AuthProvider } from "../context/Auth";
import { QueryClientProvider } from "../context/QueryClient";

const PageRecipes = React.lazy(() => import("../pages/Recipes"));

const PageDelivery = React.lazy(() => import("../pages/Delivery"));

const Root: React.FunctionComponent = () => {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout.Private />}>
              <Route
                index
                element={
                  <AppRoute.Private>
                    {process.env.NODE_ENV !== "production" ? (
                      <Page.Tasks />
                    ) : (
                      <Page.Dashboard />
                    )}
                  </AppRoute.Private>
                }
              />
              <Route
                path="/notifications/*"
                element={
                  <AppRoute.Private>
                    <Page.Notifications />
                  </AppRoute.Private>
                }
              />
              <Route
                path="/tasks/*"
                element={
                  <AppRoute.Private>
                    <Page.Tasks />
                  </AppRoute.Private>
                }
              />
              {process.env.NODE_ENV !== "production" && (
                <Route
                  path="/recipes/*"
                  element={
                    <AppRoute.Role role="admin">
                      <React.Suspense fallback={<>...</>}>
                        <PageRecipes />
                      </React.Suspense>
                    </AppRoute.Role>
                  }
                />
              )}
              <Route
                path="/delivery/*"
                element={
                  <AppRoute.Role role="admin">
                    <React.Suspense fallback={<>...</>}>
                      <PageDelivery />
                    </React.Suspense>
                  </AppRoute.Role>
                }
              />
              <Route
                path="/settings"
                element={
                  <AppRoute.Role role="customer">
                    <Page.Settings />
                  </AppRoute.Role>
                }
              />
              <Route
                path="/taskConfigurations/*"
                element={
                  <AppRoute.Role role="admin">
                    <Page.TaskConfigurations />
                  </AppRoute.Role>
                }
              />
              <Route
                path="/users/*"
                element={
                  <AppRoute.Role role="admin">
                    <Page.Users />
                  </AppRoute.Role>
                }
              />
            </Route>
            <Route element={<Layout.Public />}>
              <Route
                path="/login"
                element={
                  <AppRoute.Public>
                    <Page.Login />
                  </AppRoute.Public>
                }
              />
              <Route
                path="/register"
                element={
                  <AppRoute.Public>
                    <Page.Register />
                  </AppRoute.Public>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <AppRoute.Public>
                    <Page.ForgotPassword />
                  </AppRoute.Public>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default Root;
