import React, { useEffect, useImperativeHandle, useMemo, useRef } from "react";
import queryString from "query-string";

import { Skeleton } from "antd";
import { useQueryAuthIdToken } from "../../context/Auth";

export interface PreviewFrameProps {
  url: string;
  onMessage?: (message: any) => void;
}

export interface PreviewFrameRef {
  postMessage: (message: any, targetOrigin: string) => void;
}

// const baseURL = process.env.REACT_APP_FUNCTIONS_HOST;
const ORIGIN = "https://us-central1-advertiser-33959.cloudfunctions.net";

const PreviewFrame = React.forwardRef<PreviewFrameRef, PreviewFrameProps>(
  (props, ref) => {
    const { url, onMessage } = props;

    const frame = useRef<HTMLIFrameElement>(null);

    useImperativeHandle(ref, () => ({
      postMessage: (message, targetOrigin) => {
        frame.current?.contentWindow?.postMessage(message, targetOrigin);
      },
    }));

    const { data: tokenData } = useQueryAuthIdToken();

    const token = tokenData?.token;

    const src = useMemo(() => {
      const nextSrc = queryString.stringifyUrl({
        url: `${ORIGIN}/preview`,
        query: {
          url,
          token,
        },
      });

      return nextSrc;
    }, [token, url]);

    useEffect(() => {
      const onWindowMessage = (
        e: MessageEvent<{ type: string; data: never }>,
      ) => {
        if (e.origin !== ORIGIN) {
          return;
        }

        onMessage?.(e);
      };

      window?.addEventListener("message", onWindowMessage);

      return () => {
        return window?.removeEventListener("message", onWindowMessage);
      };
    }, [onMessage]);

    if (!token) {
      return <Skeleton />;
    }

    return (
      <iframe
        id="webview"
        ref={frame}
        src={src}
        key={url}
        title="webview"
        style={{
          width: "1900px",
          height: "1200px",
          margin: "-260px -425px",
          transform: "scale(0.55)",
        }}
        sandbox="allow-scripts allow-forms allow-same-origin"
        referrerPolicy="origin-when-cross-origin"
      />
    );
  },
);

export default PreviewFrame;
