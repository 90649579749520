import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyAWP6TSNkmu5Ux9PZQOM8hVhJ67fkQmvX8",
  authDomain: "advertiser-33959.firebaseapp.com",
  databaseURL: "https://advertiser-33959.firebaseio.com",
  projectId: "advertiser-33959",
  storageBucket: "advertiser-33959.appspot.com",
  messagingSenderId: "410320662655",
  appId: "1:410320662655:web:5140cfe80a05b32d220f4a",
  measurementId: "G-Z2269YG75Q",
};

// const enableEmulators = window.location.hostname === "localhost";
const enableEmulators = false;

const emulatorConfig = {
  auth: enableEmulators,
  functions: enableEmulators,
  firestore: enableEmulators,
};

initializeApp(config);

if (emulatorConfig.auth) {
  connectAuthEmulator(getAuth(), "http://localhost:9099");
}

if (emulatorConfig.functions) {
  connectFunctionsEmulator(getFunctions(), "localhost", 5001);
}

if (emulatorConfig.firestore) {
  connectFirestoreEmulator(getFirestore(), "localhost", 8080);
}

export { emulatorConfig };
