import get from "lodash/get";

import { Button, Space, Input } from "antd";

import { ColumnType, FilterDropdownProps } from "antd/lib/table/interface";

import { SearchOutlined } from "@ant-design/icons";

const Search = (props: FilterDropdownProps) => {
  const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;

  const onConfirm = () => {
    confirm();
  };

  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={onConfirm}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={onConfirm}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters?.()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};

const getColumnSearchProps = <RecordType,>(
  props: RequiredField<ColumnType<RecordType>, "dataIndex">,
): ColumnType<RecordType> => {
  const { dataIndex } = props;

  return {
    ...props,
    filterDropdown: (props) => <Search {...props} />,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (typeof value !== "string") {
        return false;
      }

      const recordValue = get(record, dataIndex);

      if (typeof recordValue !== "string") {
        return false;
      }

      return recordValue.includes(value);
    },
  };
};

export default getColumnSearchProps;
