import { useMemo } from "react";

import qs from "query-string";

import { getAuth } from "firebase/auth";

const useSlack = () => {
  const search = useMemo(
    () =>
      qs.stringify({
        scope: ["incoming-webhook", "chat:write"].join(","),
        client_id: "108606370419.1407866076388",
        // prettier-ignore
        redirect_uri: `${process.env.REACT_APP_FUNCTIONS_HOST}/webhooks/slack/oauth`,
        state: getAuth().currentUser?.uid,
      }),
    [],
  );

  const url = `https://slack.com/oauth/v2/authorize?${search}`;

  return {
    url,
  };
};

export { useSlack };
