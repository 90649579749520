import { getFunctions, httpsCallable } from "firebase/functions";

import { Button, message } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

const TasksRunButton = () => {
  const { t } = useTranslation(["tasks"]);

  const { mutateAsync: onScheduleTasks, isLoading: loading } = useMutation(
    () => {
      return httpsCallable(getFunctions(), "onScheduleTasks")();
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <Button
      type="primary"
      icon={<SyncOutlined />}
      loading={loading}
      onClick={() => onScheduleTasks()}
    >
      {t("tasks:button.scheduleTasks")}
    </Button>
  );
};

export default TasksRunButton;
