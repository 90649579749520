import React from "react";

import { Card, Layout, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import Meta from "antd/lib/card/Meta";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";

const RecipesPage = () => {
  const { t } = useTranslation([""]);

  return (
    <>
      <PageHeader
        title={t("recipes:title")}
        breadcrumb={{
          routes: [
            {
              path: "/recipes",
              breadcrumbName: t("recipes:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Row gutter={32}>
          <Col span={6}>
            <Link to="/recipes/product-price">
              <Card>
                <Meta
                  title="Product price"
                  description="This is the description"
                />
              </Card>
            </Link>
          </Col>
          <Col span={6}>
            <Card>
              <Meta
                title="Product price"
                description="This is the description"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Meta
                title="Product price"
                description="This is the description"
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

export default RecipesPage;
