import React from "react";
import { Descriptions } from "antd";
import dayjs from "dayjs";

function timestampToString(
  value: FirestoreTimestamp | undefined,
): string | undefined {
  const timestamp = value?.toMillis?.();

  if (!timestamp) {
    return undefined;
  }

  return dayjs(timestamp).format("L LT");
}

const TaskProductPrice = (props: TaskDocument) => {
  const { payload } = props as TaskDocumentProductPrice;

  return (
    <Descriptions bordered layout="horizontal" column={2}>
      <Descriptions.Item label="Price">Current</Descriptions.Item>
      <Descriptions.Item label="Minimum">Maximum</Descriptions.Item>

      <Descriptions.Item label="Amount">
        {payload?.currentPrice ?? "n/a"}
      </Descriptions.Item>
      <Descriptions.Item label={payload?.minimumPrice ?? "n/a"}>
        {payload?.maximumPrice ?? "n/a"}
      </Descriptions.Item>

      <Descriptions.Item label="Date">/</Descriptions.Item>
      <Descriptions.Item label={timestampToString(payload?.maximumPriceAt)}>
        {timestampToString(payload?.maximumPriceAt)}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default TaskProductPrice;
