import React, { useCallback } from "react";

import {
  Layout,
  Input,
  Button,
  Row,
  Col,
  Select,
  InputNumber,
  Divider,
} from "antd";

import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import {
  FormProvider,
  Controller,
  useForm,
  useWatch,
  useFieldArray,
} from "react-hook-form";

import PageHeader from "../../../components/PageHeader";

function escapeReplacement(string: string) {
  return string.replace(/\$/g, "$$$$");
}

interface FieldValues extends Recipe {}

const PaginationTypeIndexFormInput = () => {
  return (
    <>
      <Controller
        shouldUnregister
        name="pagination.regex"
        rules={{
          required: true,
          validate: {
            pattern: (value) => {
              let isValid = true;
              try {
                // eslint-disable-next-line no-new
                new RegExp(value);
              } catch (e) {
                isValid = false;
              }

              return isValid || "Not a valid regex";
            },
          },
        }}
        render={({ field: { value, name, onChange } }) => (
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label
                className="ant-form-item-required ant-form-item-required-mark-optional"
                htmlFor={name}
              >
                Regex
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content">
                <Input id={name} value={value} onChange={onChange} />
              </div>
            </div>
          </div>
        )}
      />
      <Controller
        shouldUnregister
        name="pagination.step"
        rules={{
          required: true,
          validate: {
            pattern: (value) =>
              Number.isNaN(parseInt(value, 10)) ? "Not a valid number" : true,
          },
        }}
        render={({ field: { value, onChange, name } }) => (
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label
                htmlFor={name}
                className="ant-form-item-required ant-form-item-required-mark-optional"
              >
                Step
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content">
                <InputNumber
                  id={name}
                  className="w-100"
                  value={value}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        )}
      />
      <Controller
        shouldUnregister
        name="pagination.start"
        rules={{
          required: true,
          validate: {
            pattern: (value) =>
              Number.isNaN(parseInt(value, 10)) ? "Not a valid number" : true,
          },
        }}
        render={({ field: { value, name, onChange } }) => (
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label
                className="ant-form-item-required ant-form-item-required-mark-optional"
                htmlFor={name}
              >
                Start
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content">
                <InputNumber
                  id={name}
                  className="w-100"
                  value={value}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

const PaginationFormInput = () => {
  const type = useWatch<FieldValues, "pagination.type">({
    name: "pagination.type",
  });

  return (
    <>
      <Controller
        name="pagination.type"
        rules={{
          required: true,
        }}
        render={({ field: { value, name, onChange } }) => (
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label
                className="ant-form-item-required ant-form-item-required-mark-optional"
                htmlFor={name}
              >
                Pagination type
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input-content">
                <Select
                  id={name}
                  value={value}
                  options={[
                    {
                      value: "index",
                      label: "Index",
                    },
                  ]}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        )}
      />
      {type === "index" && <PaginationTypeIndexFormInput />}
    </>
  );
};

// const AttributeFormInput = (props: {name: string}) => {
//     const type = useWatch<FieldValues, "fields.type">({
//     name: "pagination.type",
//   });

//   return (

//   )
// };

const FieldsFormInput = () => {
  const { fields, append } = useFieldArray<FieldValues>({
    name: "fields",
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field.id}>
            <Controller
              name={`fields.${index}.name`}
              rules={{
                required: true,
              }}
              render={({ field: { value, name, onChange } }) => (
                <div className="ant-col ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label
                      className="ant-form-item-required ant-form-item-required-mark-optional"
                      htmlFor={name}
                    >
                      Name
                    </label>
                  </div>
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input-content">
                      <Input id={name} value={value} onChange={onChange} />
                    </div>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`fields.${index}.selector`}
              rules={{
                required: true,
              }}
              render={({ field: { value, name, onChange } }) => (
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label
                      className="ant-form-item-required ant-form-item-required-mark-optional"
                      htmlFor={name}
                    >
                      Selector
                    </label>
                  </div>
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input-content">
                      <Input name={name} value={value} onChange={onChange} />
                    </div>
                  </div>
                </div>
              )}
            />
            <Controller
              name={`fields.${index}.type`}
              rules={{
                required: true,
              }}
              render={({ field: { value, name, onChange } }) => (
                <div className="ant-row ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label
                      className="ant-form-item-required ant-form-item-required-mark-optional"
                      htmlFor={name}
                    >
                      Type
                    </label>
                  </div>
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input-content">
                      <Select
                        id={name}
                        options={[
                          {
                            value: "element",
                            label: "Element",
                          },
                          {
                            value: "text",
                            label: "Text",
                          },
                          {
                            value: "number",
                            label: "Number",
                          },
                          {
                            value: "link",
                            label: "Link",
                          },
                          {
                            value: "source",
                            label: "Source",
                          },
                        ]}
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
            <Divider />
          </React.Fragment>
        );
      })}

      <Button
        onClick={() => {
          // @ts-expect-error
          append();
        }}
      >
        Add field
      </Button>
    </>
  );
};

const TaskCreate = React.memo(() => {
  const { t } = useTranslation(["taskRepeatInterval"]);

  const { pathname } = useLocation();

  const methods = useForm<FieldValues>({
    defaultValues: {
      pagination: {
        type: "index",
      },
    },
  });

  const onSubmit = useCallback((values: FieldValues) => {
    console.log(values);
    if (values.pagination.type === "index") {
      console.log("SES", escapeReplacement(values.pagination.regex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <PageHeader
        title={t("task:title")}
        breadcrumb={{
          routes: [
            {
              path: "/tasks",
              breadcrumbName: t("tasks:title"),
            },
            {
              breadcrumbName: t("task:title"),
              path: pathname,
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <form
          className="ant-form ant-form-vertical"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Row gutter={32}>
            <Col xs={24} sm={24} md={12} xxl={8}>
              <PaginationFormInput />

              <Button type="primary" htmlType="submit">
                {t("task:button.create")}
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12} xxl={8}>
              <FieldsFormInput />
            </Col>
          </Row>
        </form>
      </Layout.Content>
    </FormProvider>
  );
});

export default TaskCreate;
