import { doc, deleteDoc, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { useMutation } from "react-query";
import { Popconfirm, Button, message } from "antd";

const TaskDeleteButton = (props: { task: WithIdField<TaskDocument, "id"> }) => {
  const { task } = props;

  const { mutateAsync: onDelete, isLoading: loading } = useMutation(
    () => {
      return deleteDoc(
        doc(
          getFirestore(),
          `users/${getAuth().currentUser?.uid}/tasks/${task?.id}`,
        ),
      );
    },
    {
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  return (
    <Popconfirm
      placement="topLeft"
      title="Are you sure you want to delete this task?"
      onConfirm={() => onDelete()}
      okText="Delete"
      okType="danger"
      cancelText="Cancel"
      disabled={loading}
    >
      <Button type="primary" danger disabled={loading}>
        Delete
      </Button>
    </Popconfirm>
  );
};

export default TaskDeleteButton;
