import { useMemo } from "react";

import { Form, Select } from "antd";

import map from "lodash/map";

import { useTranslation } from "react-i18next";
import {
  where,
  query,
  collection,
  getFirestore,
  FirestoreDataConverter,
} from "firebase/firestore";

import { useFirestoreQueryData } from "@react-query-firebase/firestore";

const postConverter: FirestoreDataConverter<
  WithIdField<TaskConfiguration, "id">
> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as TaskConfiguration;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

const TaskConfiguration = (props: { type: TaskType }) => {
  const { type: taskType } = props;

  const { t } = useTranslation([""]);

  const form = Form.useFormInstance();

  const { data, isLoading: loading } = useFirestoreQueryData(
    [`taskConfigurationsPublic`, { taskType }],
    query(
      collection(getFirestore(), "taskConfigurationsPublic").withConverter(
        postConverter,
      ),
      where("taskType", "==", taskType),
    ),
    undefined,
    {
      enabled: !!taskType,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const options = useMemo(
    () =>
      map(data, ({ id, attributes }) => ({
        key: id,
        url: attributes.url,
        value: id,
        label: attributes.name,
      })),
    [data],
  );

  return (
    <Form.Item
      label={t("task:select.option.configuration.label")}
      name="configuration"
      rules={[{ required: true }]}
    >
      <Select
        options={options}
        loading={loading}
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(_value, handle) => {
          const prev = form.getFieldsValue();

          if (
            !prev?.url ||
            // @ts-expect-error
            !prev.url.startsWith(handle?.url)
          ) {
            form.setFieldsValue({
              ...prev,
              attributes: {
                ...prev?.attributes,
                // @ts-expect-error
                url: handle?.url,
              },
            });
          }
        }}
      />
    </Form.Item>
  );
};

const FormTaskConfiguration = () => {
  return (
    <Form.Item noStyle dependencies={["type"]}>
      {({ getFieldValue }) => {
        const type = getFieldValue(["type"]) as TaskType;

        if (type === "taskParkingTicket") {
          return null;
        }

        return <TaskConfiguration type={type} />;
      }}
    </Form.Item>
  );
};

export default FormTaskConfiguration;
