import React from "react";

import { getFirestore, collection, query, orderBy } from "firebase/firestore";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, Space, Table, Button } from "antd";
import { LinkOutlined, UnorderedListOutlined } from "@ant-design/icons";

import { useFirestoreQueryData } from "@react-query-firebase/firestore";

import type { ColumnsType } from "antd/lib/table";

import PageHeader from "../../../components/PageHeader";

import { getPostConverter } from "../../../lib/common/postConverter";

import { CONSOLE_HOST } from "../../../config/const";
import getColumnSearchProps from "../../../lib/common/getColumnSearchProps";

const postConverter = getPostConverter<IUser>();

const useTableColumns = <
  Data extends WithIdField<IUser, "id">,
>(): ColumnsType<Data> => {
  const { t } = useTranslation(["users"]);

  return [
    getColumnSearchProps({
      dataIndex: ["displayName"],
      title: t("users:table.header.displayName"),
      render: (value, record) => {
        return <Link to={`/users/${record.id}`}>{value ?? "n/a"}</Link>;
      },
    }),
    getColumnSearchProps({
      dataIndex: ["email"],
      title: t("users:table.header.email"),
    }),
    {
      dataIndex: "id",
      title: t("users:table.header.actions"),
      render: (_: any, record) => (
        <Space>
          <Button
            href={`${CONSOLE_HOST}/firestore/data/users/${record.id}`}
            target="_blank"
            rel="noreferrer"
            type="link"
            icon={<LinkOutlined />}
            title="Opens user document in firestore"
          >
            {t("users:button.open")}
          </Button>

          <Link to={`${record.id}/tasks`}>
            <Button type="primary" icon={<UnorderedListOutlined />}>
              {t("users:button.tasks")}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
};

const UsersPage = React.memo(() => {
  const { t } = useTranslation(["users"]);

  const { data: users, isLoading: loading } = useFirestoreQueryData(
    ["users"],
    query(
      collection(getFirestore(), `users`).withConverter(postConverter),
      orderBy("createdAt", "desc"),
    ),
    {
      subscribe: false,
    },
  );

  const columns = useTableColumns();

  return (
    <>
      <PageHeader
        title={t("users:title")}
        breadcrumb={{
          routes: [
            {
              path: ".",
              breadcrumbName: t("users:title"),
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Table
          loading={loading}
          columns={columns}
          dataSource={users ?? []}
          rowKey="id"
          scroll={{ x: true }}
          onChange={() => console.log("ASD")}
        />
      </Layout.Content>
    </>
  );
});

export default UsersPage;
