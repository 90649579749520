import React, { useContext } from "react";

import useMedia from "react-use/lib/useMedia";
import useToggle from "react-use/lib/useToggle";

type ContextType = {
  collapsed: boolean;
  setCollapsed: (nextValue?: boolean) => void;
};

const LayoutContext = React.createContext<ContextType>({
  collapsed: false,
  setCollapsed: () => null,
});

const { Provider } = LayoutContext;

export const useSidebarState = (): [boolean, (nextValue?: boolean) => void] => {
  const { collapsed, setCollapsed } = useContext(LayoutContext);

  return [collapsed, setCollapsed];
};

export const LayoutProvider: React.FC = ({ children }) => {
  const isSmall = useMedia("(max-width: 767px)");

  const [collapsed, setCollapsed] = useToggle(!!isSmall);

  return <Provider value={{ collapsed, setCollapsed }}>{children}</Provider>;
};

export default LayoutContext;
