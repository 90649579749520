import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
  collection,
  FirestoreDataConverter,
  getFirestore,
} from "firebase/firestore";

const postConverter: FirestoreDataConverter<WithIdField<TaskRunner, "id">> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as TaskRunner;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

const useQueryUserRunners = (userId: string | undefined) => {
  const collectionPath = `users/${userId}/runners`;

  return useFirestoreQueryData(
    [`users/${userId}/runners`],
    collection(getFirestore(), collectionPath).withConverter(postConverter),
    undefined,
    {
      retry: false,
      enabled: !!userId,
      refetchOnWindowFocus: false,
    },
  );
};

export { useQueryUserRunners };
