import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useAuthSendPasswordResetEmail } from "@react-query-firebase/auth";

const ForgotPassword = React.memo(() => {
  const { t } = useTranslation(["login"]);

  const { mutateAsync: sendPasswordResetEmail, isLoading: loading } =
    useAuthSendPasswordResetEmail(getAuth());

  const onSubmit = ({ email }: { email: string }) => {
    return sendPasswordResetEmail({ email })
      .then(() =>
        message.success(
          `An email has been sent to ${email} with further instructions`,
        ),
      )
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <Row
      justify="center"
      align="middle"
      className="login-layout bg-white vh-100"
    >
      <Col xs={20} sm={14} md={10} xl={6} xxl={4}>
        <Form
          layout="vertical"
          initialValues={{
            email: "",
          }}
          requiredMark="optional"
          onFinish={onSubmit}
        >
          <Form.Item
            name="email"
            label={t("forgotPassword:input.label.email")}
            extra={t("forgotPassword:input.helper.email")}
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-100"
            >
              {t("forgotPassword:button.submit")}
            </Button>
          </Form.Item>
        </Form>
        <Link to="/login">
          <Button
            type="default"
            htmlType="submit"
            disabled={loading}
            className="w-100"
          >
            {t("forgotPassword:button.login")}
          </Button>
        </Link>
      </Col>
    </Row>
  );
});

export default ForgotPassword;
