import React from "react";

import { Form, Input, Button, Row, Col, Space, Divider, message } from "antd";
import { MailOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  useAuthSignInWithEmailAndPassword,
  useAuthSignInWithPopup,
} from "@react-query-firebase/auth";

const Login = React.memo(() => {
  const { t } = useTranslation(["login", "common"]);

  const { mutateAsync: signInWithEmail, isLoading: isLoadingSignIn } =
    useAuthSignInWithEmailAndPassword(getAuth());

  const { mutateAsync: signInWithPopup, isLoading: isLoadingSignInWithPopup } =
    useAuthSignInWithPopup(getAuth());

  const loading = isLoadingSignIn || isLoadingSignInWithPopup;

  const onSubmit = (input: { email: string; password: string }) => {
    return signInWithEmail(input).catch((error) => {
      message.error(error.message);
    });
  };

  const onContinueWithGoogle = () => {
    return signInWithPopup({
      provider: new GoogleAuthProvider(),
    }).catch((error) => message.error(error.message));
  };

  return (
    <Row
      justify="center"
      align="middle"
      className="login-layout bg-white vh-100"
    >
      <Col xs={20} sm={14} md={10} xl={6} xxl={4}>
        <Form
          layout="vertical"
          initialValues={{
            email: "",
            password: "",
          }}
          requiredMark="optional"
          onFinish={onSubmit}
        >
          <Form.Item
            name="email"
            label={t("login:input.label.email")}
            rules={[
              {
                required: true,
                message: t("login:input.error.email.required"),
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 20 }}
            name="password"
            label={t("login:input.label.password")}
            rules={[
              {
                required: true,
                message: t("login:input.error.password.required"),
              },
            ]}
          >
            <Input.Password
              type="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-100"
            >
              {t("login:button.submit")}
            </Button>
          </Form.Item>
        </Form>
        <Divider plain>{t("common:or")}</Divider>
        <Space size="large" direction="vertical" className="w-100">
          <Button
            type="primary"
            loading={loading}
            icon={<GoogleOutlined />}
            className="w-100"
            onClick={onContinueWithGoogle}
          >
            {t("login:button.continueWithGoogle")}
          </Button>
          <Link to="/register">
            <Button
              type="default"
              htmlType="submit"
              disabled={loading}
              className="w-100"
            >
              {t("login:button.register")}
            </Button>
          </Link>
          <Link to="/forgot-password">
            <Button
              type="default"
              htmlType="submit"
              disabled={loading}
              className="w-100"
            >
              {t("login:button.forgotPassword")}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
});

export default Login;
