import { useMemo } from "react";

import dayjs from "dayjs";

import map from "lodash/map";
import find from "lodash/find";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  SyncOutlined,
  AppleOutlined,
  CloudOutlined,
  AndroidOutlined,
} from "@ant-design/icons";

import Tag from "antd/lib/tag";
import Space from "antd/lib/space";
import Popover from "antd/lib/popover";
import Typography from "antd/lib/typography";

import type { ColumnsType } from "antd/lib/table";

import Clock from "../../../../components/Clock";

import {
  TaskStatus,
  TaskStatusOptions,
  TaskTypeOptions,
} from "../../../../config/const";
import { useQueryAuthUser } from "../../../../context/Auth";
import { useQueryUserRunners } from "../../../../lib/hooks/useQueryUserRunners";
import getColumnSearchProps from "../../../../lib/common/getColumnSearchProps";

const { Text } = Typography;

const useTableColumns = <
  Data extends WithIdField<TaskDocument, "id">,
>(): ColumnsType<Data> => {
  const { t } = useTranslation(["tasks", "taskStatus", "taskRepeatInterval"]);

  const { data: user } = useQueryAuthUser();
  const { data: runners } = useQueryUserRunners(user?.uid);

  const runnerFilters = useMemo(() => {
    const filters = map(runners, (value) => ({
      text: value.displayName ?? value?.modelName ?? value?.platformOS,
      value: value?.id,
    }));

    return [{ value: "cloud", text: "Cloud" }, ...filters];
  }, [runners]);

  const columns: ColumnsType<Data> = [
    getColumnSearchProps({
      dataIndex: "name",
      title: t("tasks:table.header.name"),
      render: (_value, record) => {
        const to =
          record?.createdBy === user?.uid
            ? `/tasks/${record.id}`
            : `/users/${record?.createdBy}/tasks/${record.id}`;

        return <Link to={to}>{record.name}</Link>;
      },
    }),
    {
      dataIndex: "type",
      title: t("tasks:table.header.type"),
      filters: TaskTypeOptions.map((value) => ({
        text: t(`task:select.option.type.option.${value}.label`),
        value,
      })),
      onFilter: (value, record) => record?.type === value,
      render: (value) => t(`task:select.option.type.option.${value}.label`),
    },
    {
      dataIndex: "repeatInterval",
      title: t("tasks:table.header.interval"),
      render: (value) => t(`taskRepeatInterval:${value}`),
    },
    {
      dataIndex: "runner",
      title: t("tasks:table.header.runner"),
      filters: runnerFilters,
      onFilter: (value, record) => {
        if (value === "cloud") {
          return typeof record.runner !== "string";
        }

        return record.runner === value;
      },
      render: (_value, task) => {
        if (typeof task.runner !== "string") {
          // return task.runner
          return <CloudOutlined />;
        }

        const runner = find(runners, { id: task.runner });

        if (!runner) {
          return null;
        }

        const { platformOS } = runner;

        const Icon = platformOS === "android" ? AndroidOutlined : AppleOutlined;

        return (
          <Popover
            content={
              <Space direction="vertical">
                <Text>{`Version: ${runner.appVersion}`}</Text>
                <Text>{`Name: ${runner.displayName ?? runner.modelName}`}</Text>
              </Space>
            }
          >
            <Icon />
          </Popover>
        );
      },
    },
    {
      dataIndex: "performAt",
      title: t("tasks:table.header.performAt"),
      render: (_value, task) => {
        if (!task?.performAt) {
          return null;
        }

        return <Clock value={task?.performAt} />;
      },
    },
    {
      dataIndex: "status",
      title: t("tasks:table.header.status"),
      filters: TaskStatusOptions.map((value) => ({
        text: t(`taskStatus:${value}`),
        value,
      })),
      onFilter: (value, record) => record?.status === value,
      render: (status: TaskDocumentStatus, record: TaskDocument) => {
        const props = {
          color:
            // eslint-disable-next-line no-nested-ternary
            status === TaskStatus.FAILED
              ? "error"
              : status === TaskStatus.PAUSED
              ? "warning"
              : "processing",
          ...(status === TaskStatus.IN_PROGRESS && {
            icon: <SyncOutlined spin />,
          }),
        };

        const performAtDate = dayjs(record?.performAt?.toMillis?.());
        const nextExecutionTime = dayjs().isSame(performAtDate, "day")
          ? performAtDate.format("LT")
          : performAtDate.format("L LT");

        return (
          <Popover
            title={t("tasks:popup.status.scheduled.title")}
            content={
              status === "scheduled"
                ? t("tasks:popup.status.scheduled.content", {
                    time: nextExecutionTime,
                  })
                : record.statusMessage
            }
          >
            <Tag {...props}>{t(`taskStatus:${status}`)}</Tag>
          </Popover>
        );
      },
    },
  ];

  return columns;
};

export { useTableColumns };
