import React, { useCallback } from "react";

import { Form, Layout, Input, Button, message, Select, Row, Col } from "antd";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { useNavigate, useLocation } from "react-router-dom";

import { getFunctions, httpsCallable } from "firebase/functions";

import PageHeader from "../../../components/PageHeader";

import { useQueryAuthUser } from "../../../context/Auth";

import FormItemTaskType from "../Task/Form/TaskType";
import FormTaskConfiguration from "../Task/Form/TaskConfiguration";

import FormItemRunner from "../Task/Form/Runner";
import FormItemRepeatInterval from "../Task/Form/RepeatInterval";
import FormItemNotificationChannels from "../Task/Form/NotificationChannels";

const TaskCreate = React.memo(() => {
  const { t } = useTranslation(["taskRepeatInterval"]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: authUser } = useQueryAuthUser();

  const { mutate: onCreate, isLoading } = useMutation(
    (values: InputTask) =>
      httpsCallable(getFunctions(), "onCreateTask")(values),
    {
      onSuccess: () => {
        message.success(
          "Query created. Out system will soon schedule your task",
        );

        navigate("/tasks");
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
    },
  );

  const [form] = Form.useForm<InputTask>();

  const onSubmit = useCallback((values: InputTask) => {
    onCreate(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader
        title={t("task:title")}
        breadcrumb={{
          routes: [
            {
              path: "..",
              breadcrumbName: t("tasks:title"),
            },
            {
              breadcrumbName: t("task:title"),
              path: pathname,
            },
          ],
        }}
      />
      <Layout.Content className="site-layout-content">
        <Row gutter={32}>
          <Col xs={24} sm={24} md={12} xxl={8}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                name: "",
                url: "",
                type: "taskProducts",
                repeatInterval: "0 */3 * * *",
                notificationChannels: {
                  email: false,
                  slack: false,
                  notification: true,
                },
              }}
              requiredMark="optional"
              onFinish={onSubmit}
            >
              <Form.Item
                label={t("task:input.label.name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("task:input.error.name.required"),
                  },
                ]}
              >
                <Input placeholder={t("task:input.placeholder.name")} />
              </Form.Item>

              <Form.Item
                label={t("task:select.option.type.label")}
                name="type"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    {
                      value: "taskProducts",
                      label: t(
                        "task:select.option.type.option.taskProducts.label",
                      ),
                    },
                    {
                      value: "taskProductPrice",
                      label: t(
                        "task:select.option.type.option.taskProductPrice.label",
                      ),
                    },
                    {
                      value: "taskParkingTicket",
                      label: t(
                        "task:select.option.type.option.taskParkingTicket.label",
                      ),
                    },
                  ]}
                />
              </Form.Item>

              <FormTaskConfiguration />

              <FormItemTaskType />

              <FormItemRepeatInterval />

              <FormItemRunner userId={authUser?.uid} />

              <FormItemNotificationChannels />

              <Form.Item>
                <Button disabled={isLoading} type="primary" htmlType="submit">
                  {t("task:button.create")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
});

export default TaskCreate;
