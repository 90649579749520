import { useMemo } from "react";

import { Form, Input, Select, FormItemProps, Button } from "antd";

import { useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { doc, getFirestore, FirestoreDataConverter } from "firebase/firestore";

import { useTranslation } from "react-i18next";
import { LinkOutlined } from "@ant-design/icons";

const postConverter: FirestoreDataConverter<
  WithIdField<TaskConfiguration, "id">
> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot) {
    const data = snapshot.data() as TaskConfiguration;

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

const FormTaskTypeURL = (props: { configuration?: string }) => {
  const { t } = useTranslation(["task"]);

  const { configuration } = props;

  const { data } = useFirestoreDocumentData(
    [`taskConfigurationsPublic/${configuration}`],
    doc(
      getFirestore(),
      `taskConfigurationsPublic/${configuration}`,
    ).withConverter(postConverter),
    undefined,
    {
      enabled: !!configuration,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const rules = useMemo((): Required<FormItemProps>["rules"] => {
    const nextRules: Required<FormItemProps>["rules"] = [
      {
        required: true,
        message: t("task:input.error.attributes.url.required"),
      },
      {
        validator: (_rule, value) => {
          const valid = data?.validate?.some?.((val) =>
            new RegExp(val.pattern, val.flags).test(value),
          );

          if (valid) {
            return Promise.resolve();
          }

          return Promise.reject(t("task:input.error.attributes.url.pattern"));
        },
      },
    ];

    return nextRules;
  }, [data?.validate, t]);

  return (
    <Form.Item noStyle dependencies={["attributes", "url"]}>
      {({ getFieldValue }) => {
        const url = getFieldValue(["attributes", "url"]);

        return (
          <Form.Item
            label={t("task:input.label.attributes.url")}
            name={["attributes", "url"]}
            rules={rules}
          >
            <Input
              disabled={!data}
              suffix={
                <Button
                  type="link"
                  icon={<LinkOutlined />}
                  onClick={() => {
                    if (!(typeof url === "string" && url.startsWith("http"))) {
                      return;
                    }

                    window.open(url, "_blank");
                  }}
                />
              }
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

const FormTaskType = () => {
  const { t } = useTranslation(["task"]);

  return (
    <Form.Item noStyle dependencies={["type", "configuration"]}>
      {({ getFieldValue }) => {
        const type = getFieldValue(["type"]) as TaskType;
        const configuration = getFieldValue(["configuration"]);

        switch (type) {
          case "taskProducts":
          case "taskProductPrice": {
            return <FormTaskTypeURL configuration={configuration} />;
          }

          case "taskParkingTicket": {
            return (
              <>
                <Form.Item
                  label={t("task:input.label.attributes.licensePlate")}
                  name={["attributes", "licensePlate"]}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "task:input.error.attributes.licensePlate.required",
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("task:input.label.attributes.city")}
                  name={["attributes", "city"]}
                  initialValue="NI"
                  rules={[
                    {
                      required: true,
                      message: t("task:input.error.attributes.city.required"),
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        value: "NI",
                        label: t(
                          "task:select.option.attributes.city.option.NI.label",
                        ),
                      },
                      {
                        value: "BG",
                        label: t(
                          "task:select.option.attributes.city.option.BG.label",
                        ),
                      },
                    ]}
                  />
                </Form.Item>
              </>
            );
          }

          default: {
            return null;
          }
        }
      }}
    </Form.Item>
  );
};

export default FormTaskType;
