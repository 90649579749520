import React, { useCallback } from "react";

import { getAuth } from "firebase/auth";
import { useAuthSignOut } from "@react-query-firebase/auth";
import { doc, getFirestore, updateDoc, arrayRemove } from "firebase/firestore";

import useToggle from "react-use/lib/useToggle";

import { Link } from "react-router-dom";
import {
  Button,
  Layout,
  Popover,
  List,
  Badge,
  Menu,
  Space,
  Dropdown,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NotificationListItem from "../../NotificationListItem";

import { useSidebarState } from "../../../context/Layout";
import { useQueryAuthUser, useQueryCurrentUser } from "../../../context/Auth";

import { useQueryUserNotifications } from "../../../lib/hooks/useQueryUserNotifications";

const NotificationsMenu = () => {
  const [visible, setVisible] = useToggle(false);

  const { data: user } = useQueryCurrentUser();
  const { data: authUser } = useQueryAuthUser();

  const { data: notifications } = useQueryUserNotifications();

  const renderNotification = useCallback(
    (item: IUserNotification) => {
      const { id } = item;

      const read = !user?.unreadNotifications?.includes(id);

      const onClick = () => {
        if (!read) {
          updateDoc(doc(getFirestore(), `users/${authUser?.uid}`), {
            unreadNotifications: arrayRemove(id),
          });
        }

        setVisible(false);
      };

      return (
        <Link key={id} to={`/notifications/${id}`} onClick={onClick}>
          <NotificationListItem read={read} item={item} />
        </Link>
      );
    },
    [user?.unreadNotifications, setVisible, authUser?.uid],
  );

  const onReadAllNotifications = useCallback(() => {
    updateDoc(doc(getFirestore(), `users/${authUser?.uid}`), {
      unreadNotifications: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover
      trigger="click"
      title="Notifications"
      visible={visible}
      onVisibleChange={setVisible}
      align={{
        points: ["br", "tr"],
      }}
      overlayStyle={{ width: 400, maxWidth: "100%" }}
      content={
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={renderNotification}
          footer={
            <>
              <Button type="link" onClick={onReadAllNotifications}>
                Mark all as read
              </Button>
              <Link to="/notifications" onClick={() => setVisible(false)}>
                <Button type="link">See all notifications</Button>
              </Link>
            </>
          }
        />
      }
    >
      <Badge count={user?.unreadNotifications?.length}>
        <Button shape="circle" icon={<BellOutlined />} />
      </Badge>
    </Popover>
  );
};

const UserMenu = () => {
  const { t } = useTranslation(["header"]);

  const { data: currentUser } = useQueryAuthUser();

  const { mutateAsync: signOut } = useAuthSignOut(getAuth());

  const menu = (
    <Menu style={{ minWidth: 160 }}>
      <Menu.Item key="logout" onClick={() => signOut()} danger>
        {t("header:menu.item.logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="primary" shape="circle">
        {currentUser?.displayName?.[0] ?? "A"}
      </Button>
    </Dropdown>
  );
};

const PrivateHeader = () => {
  const [collapsed, setCollapsed] = useSidebarState();

  return (
    <Layout.Header className="site-layout-header">
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: () => setCollapsed(),
        style: { color: "#fff" },
      })}
      <Space direction="horizontal" size="middle" align="center">
        <NotificationsMenu />
        <UserMenu />
      </Space>
    </Layout.Header>
  );
};

export default PrivateHeader;
