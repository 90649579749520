import { Routes, Route } from "react-router-dom";

import TaskConfigurations from "./TaskConfigurations";

const TaskConfigurationsRouter = () => {
  return (
    <Routes>
      <Route index element={<TaskConfigurations />} />
      {/* <Route path=":userId/tasks" element={<UsersTasksRoute />} />
      <Route path=":userId/tasks/:taskId" element={<UsersTaskRoute />} /> */}
    </Routes>
  );
};

export default TaskConfigurationsRouter;
